import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Nav, Navbar, Offcanvas, Table } from "react-bootstrap";
import { BrowserRouter, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import './App.css';
import { swalConfirm, swalSuccess } from "./commons/modal/SwalAlertCustom";
import { moneyFormat } from "./util/number_format";

function Process({ menu }) {
  const [searchParams, setSearchParams] = useSearchParams();
  // if (searchParams.get("key") && searchParams.get("key") === "689a02ec0d7d70422e0807309590e9191bca") {

  const [listUser, setListUser] = useState([]);
  const [listAuctionPayment, setListAuctionPayment] = useState([]);
  const [listReportAuction, setListReportAuction] = useState([]);
  const [listWinnerAuction, setListWinnerAuction] = useState([]);

  async function fetch() {
    try {
      const url = `${process.env.REACT_APP_API_URL}/bo/user-list?key=${ searchParams.get("key") || ''}`;
      const res = await axios.get(url);
      if (res.status === 200) {
        setListUser(res.data.data);
      }
    } catch (e) {
      console.log("e", e.message)
    }
  }

  async function fetch2() {
    try {
      const url = `${process.env.REACT_APP_API_URL}/bo/auction-payment-list?key=${ searchParams.get("key") || ''}`;
      const res = await axios.get(url);
      if (res.status === 200) {
        setListAuctionPayment(res.data.data);
      }
    } catch (e) {
      console.log("e", e.message)
    }
  }

  async function fetch3() {
    try {
      const actionLotId = 1;
      const url = `${process.env.REACT_APP_API_URL}/bo/report-auction/${actionLotId}?key=${ searchParams.get("key") || ''}`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const list = res.data.data;

        for (const item of list) {
          const itemWinner = await fetch4(item.id);
          // listWinner.push(itemWinner);
          item.listWinner = itemWinner;
        }
        setListReportAuction(list);
        // setListWinnerAuction(listWinner);
      }
    } catch (e) {
      console.log("e", e.message)
    }
  }

  async function fetch3_1() {
    try {
      const actionLotId = 2;
      const url = `${process.env.REACT_APP_API_URL}/bo/report-auction/${actionLotId}?key=${ searchParams.get("key") || ''}`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const list = res.data.data;

        for (const item of list) {
          const itemWinner = await fetch4(item.id);
          // listWinner.push(itemWinner);
          item.listWinner = itemWinner;
        }
        setListReportAuction(list);
        // setListWinnerAuction(listWinner);
      }
    } catch (e) {
      console.log("e", e.message)
    }
  }

  async function fetch3_4() {
    try {
      const actionLotId = 3;
      const url = `${process.env.REACT_APP_API_URL}/bo/report-auction/${actionLotId}?key=${ searchParams.get("key") || ''}`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const list = res.data.data;

        for (const item of list) {
          const itemWinner = await fetch4(item.id);
          // listWinner.push(itemWinner);
          item.listWinner = itemWinner;
        }
        setListReportAuction(list);
        // setListWinnerAuction(listWinner);
      }
    } catch (e) {
      console.log("e", e.message)
    }
  }


  async function fetch4(auctionItemId) {
    try {
      const url = `${process.env.REACT_APP_API_URL}/bo/auction-item/${auctionItemId}?key=${ searchParams.get("key") || ''}`;
      const res = await axios.get(url);
      if (res.status === 200) {
        // setListWinnerAuction(res.data.data);
       return res.data.data;
      }
    } catch (e) {
      console.log("e", e.message)
    }
  }

  async function onUpdateAuctionPaymentClick(item) {
    swalConfirm(`เปลี่ยนสถานะเป็น "ชำระเงิน: สำเร็จ"`, ()=> {
      updateAuctionPayment(item.id);
    });
  }

  async function updateAuctionPayment(userPackageOrderId) {
    const url = `${process.env.REACT_APP_API_URL}/bo/auction-payment/${userPackageOrderId}?key=${ searchParams.get("key") || ''}`;
    const body = {
      status: "SUCCESS"
    }
    const res = await axios.put(url, body);
    if (res.status === 200 && res.data.code === 100) {
      swalSuccess("ทำรายการสำเร็จ")
      await fetch2();
    }
  }

  async function onUpdateRegisterPaymentClick(item) {
    swalConfirm(`เปลี่ยนสถานะเป็น "ชำระเงิน: สำเร็จ"`, ()=> {
      updateRegisterPayment(item.id);
    });
  }

  async function updateRegisterPayment(userId) {
    const url = `${process.env.REACT_APP_API_URL}/bo/register-payment/${userId}?key=${ searchParams.get("key") || ''}`;
    const body = {}
    const res = await axios.put(url, body);
    if (res.status === 200 && res.data.code === 100) {
      swalSuccess("ทำรายการสำเร็จ")
      await fetch();
    }
  }

  useEffect(() => {
    if (menu === 1)
      fetch();
    else if (menu === 2)
      fetch2();
    else if (menu === 3)
      fetch3();
    else if (menu === 33)
      fetch3_1();
    else if (menu === 34)
      fetch3_4();

  }, [menu]);

  if (menu === 1) {
    if (listUser.length > 0) {
      return (
        <Container fluid className="pt-3">
          <h3>สมาชิก MCTA</h3>
          <div>ผลการสมัครสมาชิกผู้เข้าร่วมประมูล Maehongson Coffee Trade Association</div>
          <Table bordered hover responsive>
            <thead>
            <tr>
              <th>#</th>
              <th>ลงทะเบียนในนาม</th>
              <th>อีเมล์</th>
              <th>ชื่อเต็ม</th>
              <th>หมายเลขโทรศัพท์</th>
              <th>ที่อยู่</th>
              <th>ที่อยู่ บันทัดที่ 2</th>
              <th>รหัสไปรษณีย์</th>
              <th>จังหวัด</th>
              <th>เวลาสมัคร</th>
              <th>ชำระค่าสมัคร</th>
              <th>remark</th>
            </tr>
            </thead>
            <tbody>
            {listUser.map((item, index) => (
              <tr key={item.id}>
                <td className="FontSS">{index + 1}</td>
                <td className="FontSS">{item?.nickname}</td>
                <td className="FontSS">{item?.email}</td>
                <td className="FontSS">{item?.fullname}</td>
                <td className="FontSS">{item?.phone}</td>
                <td className="FontSS" style={{ fontSize: 11 }}>{item?.address}</td>
                <td className="FontSS" style={{ fontSize: 11 }}>{item?.address2}</td>
                <td className="FontSS">{item?.zipCode}</td>
                <td className="FontSS">{item?.province}</td>
                <td className="FontSS">{item?.createdAt}</td>
                <td className="FontSS">
                  {item?.status === "ACTIVE" ? (
                    <span className="text-success">ชำระเงินสำเร็จ</span>
                  ) : item?.status === "PENDING" ? (
                    <div className="text-primary" style={{ cursor: "pointer" }} onClick={()=>onUpdateRegisterPaymentClick(item)}>รอชำระเงิน</div>
                  ) : item?.status }
                </td>
                <td>
                  {item.remark}
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Container>
      )
    } else {
      return <div>Access Denied</div>;
    }
  } else if (menu === 2){
    if (listAuctionPayment.length > 0) {
      return (
        <Container fluid className="pt-3">
          <h3>ผู้ลงทะเบียนเข้าร่วมประมูล</h3>
          <div>รายการลงทะเบียนเข้าร่วมการประมูล</div>
          <Table bordered hover responsive>
            <thead>
            <tr>
              <th>#</th>
              <th>ลงทะเบียนในนาม</th>
              <th>อีเมล์</th>
              <th>ชื่อเต็ม</th>
              <th>หมายเลขโทรศัพท์</th>
              <th>เวลาขอเข้าร่วมประมูล</th>
              <th>ชำระค่าเข้าร่วมประมูล</th>
            </tr>
            </thead>
            <tbody>
            {listAuctionPayment.map((item, index) => (
              <tr key={item.id}>
                <td className="FontSS">{index + 1}</td>
                <td className="FontSS">{item.user?.nickname}</td>
                <td className="FontSS">{item.user?.email}</td>
                <td className="FontSS">{item.user?.fullname}</td>
                <td className="FontSS">{item.user?.phone}</td>
                <td className="FontSS">{item?.createdAt}</td>
                <td className="FontSS">
                  {item?.status === "SUCCESS" ? (
                    <span className="text-success">ชำระเงินสำเร็จ</span>
                  ) : item?.status === "PAY_MORE" ? (
                    <div className="text-primary" style={{ cursor: "pointer" }} onClick={()=>onUpdateAuctionPaymentClick(item)}>รอชำระเงิน</div>
                  ) : item?.status}
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Container>
      )
    } else {
      return <div>Access Denied</div>;
    }
  } else if (menu === 3) {
    if (listReportAuction.length > 0) {
      return (
        <Container fluid className="pt-3">
          <Container>
            <h5 className="text-center">ผลการประมูลรอบที่ 1</h5>
            {listReportAuction.map((item, index) => (
              <div key={item.id} className="mb-3">
                <Card className="shadow border-white">
                  <Card.Body>
                    <h5>Ranking: {item.ranking}</h5>
                    <small>Code: {item.code}</small> <br/>
                    <small>Process: {item.process}</small><br/>
                    <small>Taste Note: {item.tasteNote}</small><br/>
                    <small>Cupping Score: {item.cuppingScore}</small><br/>
                    <small>Volume: {item.stockQty}</small><br/>
                    <Table bordered hover responsive>
                      <thead>
                      <tr>
                        <th>อันดับ</th>
                        <th>ราคา</th>
                        <th>จำนวน (กิโลกรัม)</th>
                        <th>ร้านกาแฟ</th>
                      </tr>
                      </thead>
                      <tbody>
                      {item.listWinner?.map((itemWinner, index) => (
                        <tr key={itemWinner.id}>
                          <td className="FontSS">{index + 1}</td>
                          <td className="FontSS">{itemWinner?.bidPrice}</td>
                          <td className="FontSS">{itemWinner?.bidKg}</td>
                          <td className="FontSS">{itemWinner?.nickname}</td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Container>

          <hr className="m-5" />
          <h3>รายการประมูล</h3>
          <div>รายการประมูล</div>
          <Table bordered hover responsive>
            <thead>
            <tr>
              <th>#</th>
              <th>Ranking</th>
              <th>Code</th>
              <th>Process</th>
              <th>Taste Note</th>
              <th>Cupping Score</th>
              <th>Volume</th>
              <th>ราคาประมูลเฉลี่ยตอนนี้</th>
              <th>ราคาประมูลสูงสุดตอนนี้</th>
              <th>จำนวนผู้ประมูลรายการนี้</th>
            </tr>
            </thead>
            <tbody>
            {listReportAuction.map((item, index) => (
              <tr key={item.id}>
                <td className="FontSS">{index + 1}</td>
                <td className="FontSS">{item?.ranking}</td>
                <td className="FontSS">{item?.code}</td>
                <td className="FontSS">{item?.process}</td>
                <td className="FontSS">{item?.tasteNote}</td>
                <td className="FontSS">{item?.cuppingScore}</td>
                <td className="FontSS text-center">{item?.stockQty}</td>
                <td className="FontSS text-center">{item?.bidPriceAverage}</td>
                <td className="FontSS text-center">{item?.bidPriceMax}</td>
                <td className="FontSS text-center">{item?.countAuctionUser}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Container>
      )
    } else {
      return <div>Access Denied</div>;
    }
  } else if (menu === 33) {
    if (listReportAuction.length > 0) {
      return (
        <Container fluid className="pt-3">
          <Container>
            <h5 className="text-center">ผลการประมูลรอบที่ 2</h5>
            {listReportAuction.map((item, index) => (
              <div key={item.id} className="mb-3">
                <Card className="shadow border-white">
                  <Card.Body>
                    <h5>Auction No. {item.code}</h5>
                    <small>ชื่อเกษตรกร: {item.processor}</small> <br/>
                    <small>Origin: {item.origin}</small><br/>
                    <small>Elevation (MASL): { moneyFormat(item.elevation, 0)}</small><br/>
                    <small>Preparation: {item.process}</small><br/>
                    <small>Farm: {item.farm}</small><br/>
                    <small>Taste Note: {item.tasteNote}</small><br/>
                    {/*<small>Cupping Score: {item.cuppingScore}</small><br/>*/}
                    <small>Volume: {item.stockQty}</small><br/>
                    <Table bordered hover responsive>
                      <thead>
                      <tr>
                        <th>อันดับ</th>
                        <th>ราคา</th>
                        <th>จำนวน (กิโลกรัม)</th>
                        <th>ร้านกาแฟ</th>
                      </tr>
                      </thead>
                      <tbody>
                      {item.listWinner?.map((itemWinner, index) => (
                        <tr key={itemWinner.id}>
                          <td className="FontSS">{index + 1}</td>
                          <td className="FontSS">{itemWinner?.bidPrice}</td>
                          <td className="FontSS">{itemWinner?.bidKg}</td>
                          <td className="FontSS">{itemWinner?.nickname}</td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Container>

          <hr className="m-5" />
          <h3>รายการประมูล</h3>
          <div>รายการประมูล</div>
          <Table bordered hover responsive>
            <thead>
            <tr>
              <th>#</th>
              <th>Ranking</th>
              <th>Code</th>
              <th>Process</th>
              <th>Taste Note</th>
              <th>Cupping Score</th>
              <th>Volume</th>
              <th>ราคาประมูลเฉลี่ยตอนนี้</th>
              <th>ราคาประมูลสูงสุดตอนนี้</th>
              <th>จำนวนผู้ประมูลรายการนี้</th>
            </tr>
            </thead>
            <tbody>
            {listReportAuction.map((item, index) => (
              <tr key={item.id}>
                <td className="FontSS">{index + 1}</td>
                <td className="FontSS">{item?.ranking}</td>
                <td className="FontSS">{item?.code}</td>
                <td className="FontSS">{item?.process}</td>
                <td className="FontSS">{item?.tasteNote}</td>
                <td className="FontSS">{item?.cuppingScore}</td>
                <td className="FontSS text-center">{item?.stockQty}</td>
                <td className="FontSS text-center">{item?.bidPriceAverage}</td>
                <td className="FontSS text-center">{item?.bidPriceMax}</td>
                <td className="FontSS text-center">{item?.countAuctionUser}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Container>
      )
    } else {
      return <div>Access Denied</div>;
    }
  } else if (menu === 34) {
    if (listReportAuction.length > 0) {
      return (
        <Container fluid className="pt-3">
          <Container>
            <h5 className="text-center">ผลการประมูลรอบที่ 3</h5>
            {listReportAuction.map((item, index) => (
              <div key={item.id} className="mb-3">
                <Card className="shadow border-white">
                  <Card.Body>
                    <h5>Auction No. {item.code}</h5>
                    <small>ชื่อเกษตรกร: {item.processor}</small> <br/>
                    <small>Origin: {item.origin}</small><br/>
                    <small>Elevation (MASL): { moneyFormat(item.elevation, 0)}</small><br/>
                    <small>Preparation: {item.process}</small><br/>
                    <small>Farm: {item.farm}</small><br/>
                    <small>Taste Note: {item.tasteNote}</small><br/>
                    {/*<small>Cupping Score: {item.cuppingScore}</small><br/>*/}
                    <small>Volume: {item.stockQty}</small><br/>
                    <Table bordered hover responsive>
                      <thead>
                      <tr>
                        <th>อันดับ</th>
                        <th>ราคา</th>
                        <th>จำนวน (กิโลกรัม)</th>
                        <th>ร้านกาแฟ</th>
                      </tr>
                      </thead>
                      <tbody>
                      {item.listWinner?.map((itemWinner, index) => (
                        <tr key={itemWinner.id}>
                          <td className="FontSS">{index + 1}</td>
                          <td className="FontSS">{itemWinner?.bidPrice}</td>
                          <td className="FontSS">{itemWinner?.bidKg}</td>
                          <td className="FontSS">{itemWinner?.nickname}</td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Container>

          <hr className="m-5" />
          <h3>รายการประมูล</h3>
          <div>รายการประมูล</div>
          <Table bordered hover responsive>
            <thead>
            <tr>
              <th>#</th>
              <th>Ranking</th>
              <th>Code</th>
              <th>Process</th>
              <th>Taste Note</th>
              <th>Cupping Score</th>
              <th>Volume</th>
              <th>ราคาประมูลเฉลี่ยตอนนี้</th>
              <th>ราคาประมูลสูงสุดตอนนี้</th>
              <th>จำนวนผู้ประมูลรายการนี้</th>
            </tr>
            </thead>
            <tbody>
            {listReportAuction.map((item, index) => (
              <tr key={item.id}>
                <td className="FontSS">{index + 1}</td>
                <td className="FontSS">{item?.ranking}</td>
                <td className="FontSS">{item?.code}</td>
                <td className="FontSS">{item?.process}</td>
                <td className="FontSS">{item?.tasteNote}</td>
                <td className="FontSS">{item?.cuppingScore}</td>
                <td className="FontSS text-center">{item?.stockQty}</td>
                <td className="FontSS text-center">{item?.bidPriceAverage}</td>
                <td className="FontSS text-center">{item?.bidPriceMax}</td>
                <td className="FontSS text-center">{item?.countAuctionUser}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Container>
      )
    } else {
      return <div>Access Denied</div>;
    }
  }

}

function App() {
  const [menu, setMenu] = useState(34);
  return (
    <BrowserRouter>
      <NavCustom setMenu={setMenu}/>
      <Process menu={menu}/>
    </BrowserRouter>
  );
}

export default App;


/**
 * COMP...
 */
function NavCustom({ setMenu }) {
  const [expand, setExpand] = useState(false)
  return (
    <Navbar variant="dark" expand={expand} className="bg-body-tertiaryxx mb-3 BgColorCoffee3x bg-dark">
      <Container fluid>
        <Navbar.Brand >{process.env.REACT_APP_TITLE}</Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}/>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              เมนู
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-column flex-grow-1 pe-3 ">
              {/*<Nav.Link href="/register-success" className="text-dark"><MdOutlineAccountTree/> การลงทะเบียน</Nav.Link>*/}
              <Nav.Link className="TextColorGray" onClick={()=> setMenu(34) }> <div className="text-primary">ผลการประมูลรอบที่ 3</div></Nav.Link>
              <Nav.Link className="TextColorGray" onClick={()=> setMenu(33) }> <div >ผลการประมูลรอบที่ 2</div></Nav.Link>
              <Nav.Link className="TextColorGray" onClick={()=> setMenu(3) }> <div >ผลการประมูลรอบที่ 1</div></Nav.Link>
              <Nav.Link className="TextColorGray" onClick={() => setMenu(1)}> สมาชิก MCTA</Nav.Link>
              <Nav.Link className="TextColorGray" onClick={()=> setMenu(2) }> ผู้ลงทะเบียนเข้าร่วมประมูล</Nav.Link>
            </Nav>
            <hr/>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}
