import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export const swalError = (message) => {
  withReactContent(Swal).fire({
    icon: "error",
    text: message,
    showConfirmButton: false,
    showCloseButton: true,
  })
}

export const swalWarning = (message) => {
  withReactContent(Swal).fire({
    icon: "warning",
    text: message,
    showConfirmButton: false,
    showCloseButton: true,
  })
}

export const swalSuccess = (message) => {
  withReactContent(Swal).fire({
    icon: "success",
    text: message,
    showConfirmButton: false,
    showCloseButton: true,
    timer: 5000
  })
}

export const swalConfirm = (message, onConfirm) => {
  withReactContent(Swal).fire({
    // icon: "success",
    text: message,
    showCloseButton: true,

    showConfirmButton: true,
    confirmButtonColor: "#32c36c",
    confirmButtonText: "ยืนยัน",

    showCancelButton: true,
    cancelButtonColor: "#d33",
    cancelButtonText: "ยกเลิก",
    preConfirm: onConfirm
  });
}

export const swalConfirmHtml = (title, html, onConfirm) => {
  withReactContent(Swal).fire({
    // icon: "success",
    title: title,
    html: html,
    showCloseButton: true,

    showConfirmButton: true,
    confirmButtonColor: "#32c36c",
    confirmButtonText: "ยืนยัน",

    showCancelButton: true,
    cancelButtonColor: "#d33",
    cancelButtonText: "ยกเลิก",
    preConfirm: onConfirm
  })
  //   .then((result) => {
  //   if (result.isConfirmed) {
  //     Swal.fire({
  //       title: `${result.value.login}'s avatar`,
  //       imageUrl: result.value.avatar_url
  //     });
  //   }
  // });
}
